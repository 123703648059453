import { getCookie, setCookie } from "./Cookies";
const COVID_COOKIE_SEEN = "covidBannerSeen";

export class CovidBanner {
  el: HTMLDivElement;
  constructor(el) {
    this.el = el;
    if (getCookie(COVID_COOKIE_SEEN)) {
      this.el.remove();
    } else {
      this.el.classList.remove("hidden");
      this.el
        .querySelector(".bab-icon-button")
        .addEventListener("click", (e) => {
          this.el.remove();
          setCookie(COVID_COOKIE_SEEN, true, 3);
        });
    }
  }
}
