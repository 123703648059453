export class BabField {
  el: HTMLInputElement | HTMLTextAreaElement;
  initialPlaceholder: string;
  constructor(el: HTMLInputElement) {
    this.el = el;
    this.el.addEventListener("focus", this.onFocus);
    this.el.addEventListener("blur", this.onBlur);
    this.initialPlaceholder = this.el.placeholder;
  }
  onFocus = (e: FocusEvent) => {
    this.el.placeholder = this.el.dataset.altPlaceholder || " ";
  };
  onBlur = (e: FocusEvent) => {
    this.el.placeholder = this.initialPlaceholder;
  };
}
