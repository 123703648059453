import Pristine from "pristinejs";
import { BabField } from "./ts/Inputs";
import { CovidBanner } from "./ts/Covid";

const $ = (selector: string): HTMLElement => document.querySelector(selector);
const $$ = (selector: string): NodeList => document.querySelectorAll(selector);

const navBtn = $(".bab-nav-toggle");
navBtn.addEventListener("click", (e) =>
  navBtn.setAttribute(
    "aria-expanded",
    navBtn.getAttribute("aria-expanded") === "true" ? "false" : "true"
  )
);

$("#covidBanner") && new CovidBanner($("#covidBanner"));
let validation;

const formSetup = () => {
  const script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyDhuSiqQ9It7z4KXSYxTaEvEHknCm2Fcuc&libraries=places&callback=initPlaces";
  script.defer = true;
  script.async = true;
  // Append the 'script' element to 'head'
  document.head.appendChild(script);
  $$("[data-alt-placeholder]").forEach(
    (el) => new BabField(el as HTMLInputElement)
  );
  const bookingForm = $("#book") as HTMLFormElement;
  const bounce = $("#bounce") as HTMLInputElement;
  bounce.value = document.location.pathname;
  if (bookingForm) {
    validation = new Pristine(bookingForm, {
      classTo: "bab-form-field",
      errorClass: "bab-form-error",
      errorTextParent: "bab-form-field",
    });
    validation.addValidator(
      $("#dateTime"),
      function (value) {
        if (!value.length) return false;
        const pickupDateTime = new Date(value);
        if (isNaN(pickupDateTime.getTime())) return false;
        // here `this` refers to the respective input element
        const futureDate = new Date();
        futureDate.setHours(futureDate.getHours() + 1);
        if (pickupDateTime < futureDate) return false;
        return true;
      },
      "Pickup should be at least 1 hour from now. If your request is more urgent, please give us a call.",
      2,
      false
    );
    const contactNo = $("#contactNo") as HTMLInputElement;
    bookingForm.addEventListener("submit", async (e) => {
      e.preventDefault();

      if (validation.validate()) {
        let data = await (
          await fetch("/validation/check/" + contactNo.value)
        ).json();
        if (!data.valid) {
          validation.addError(
            contactNo,
            "Enter full phone number with country code prefix e.g +44 (UK), +1 (US)."
          );
          return false;
        }
        bookingForm.submit();
      } else {
        return false;
      }
    });
  }
};

globalThis.initPlaces = () => {
  const defaultBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(50.76028905603908, -2.179328906250002),
    new google.maps.LatLng(52.242458141384674, 2.434928906249998)
  );

  var elFrom = document.getElementById("from") as HTMLInputElement;
  var elTo = document.getElementById("to") as HTMLInputElement;
  var options = {
    bounds: defaultBounds,
    country: ["uk"],
  };

  const fromAutocomplete = new google.maps.places.Autocomplete(elFrom, options);
  fromAutocomplete.addListener("place_changed", (e) => {
    if (elFrom.value) {
      validation.validate([elFrom]);
    }
  });
  const toAutocomplete = new google.maps.places.Autocomplete(elTo, options);
  toAutocomplete.addListener("place_changed", (e) => {
    if (elTo.value) {
      validation.reset();
      validation.validate([elTo]);
    }
  });
};
formSetup();

const heroSetup = () => {
  window.addEventListener("load", () => {
    $("#babHero").classList.add("loaded");
  });
};
$("#babHero") && heroSetup();

window.addEventListener("load", () =>
  navigator.serviceWorker
    .register("./bab-sw.js")
    .then(() => console.log("SW Installed"))
);
